/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { bulkDeleteContentPermissions } from '../fn/content/bulk-delete-content-permissions';
import { BulkDeleteContentPermissions$Params } from '../fn/content/bulk-delete-content-permissions';
import { ContentAttachmentDetail } from '../models/content-attachment-detail';
import { ContentDetail } from '../models/content-detail';
import { createContent } from '../fn/content/create-content';
import { CreateContent$Params } from '../fn/content/create-content';
import { createContentLocation } from '../fn/content/create-content-location';
import { CreateContentLocation$Params } from '../fn/content/create-content-location';
import { createContentPermission } from '../fn/content/create-content-permission';
import { CreateContentPermission$Params } from '../fn/content/create-content-permission';
import { createContentVersion } from '../fn/content/create-content-version';
import { CreateContentVersion$Params } from '../fn/content/create-content-version';
import { deleteAttachment } from '../fn/content/delete-attachment';
import { DeleteAttachment$Params } from '../fn/content/delete-attachment';
import { deleteContent } from '../fn/content/delete-content';
import { DeleteContent$Params } from '../fn/content/delete-content';
import { deleteContentLocation } from '../fn/content/delete-content-location';
import { DeleteContentLocation$Params } from '../fn/content/delete-content-location';
import { deleteContentPermissions } from '../fn/content/delete-content-permissions';
import { DeleteContentPermissions$Params } from '../fn/content/delete-content-permissions';
import { deleteContentVersion } from '../fn/content/delete-content-version';
import { DeleteContentVersion$Params } from '../fn/content/delete-content-version';
import { getContent } from '../fn/content/get-content';
import { GetContent$Params } from '../fn/content/get-content';
import { getContentAttachment } from '../fn/content/get-content-attachment';
import { GetContentAttachment$Params } from '../fn/content/get-content-attachment';
import { GetContentListResponse } from '../models/get-content-list-response';
import { GetContentLocationListResponse } from '../models/get-content-location-list-response';
import { getContentLocations } from '../fn/content/get-content-locations';
import { GetContentLocations$Params } from '../fn/content/get-content-locations';
import { getContentPermissions } from '../fn/content/get-content-permissions';
import { GetContentPermissions$Params } from '../fn/content/get-content-permissions';
import { getContentVersionById } from '../fn/content/get-content-version-by-id';
import { GetContentVersionById$Params } from '../fn/content/get-content-version-by-id';
import { getContentVersions } from '../fn/content/get-content-versions';
import { GetContentVersions$Params } from '../fn/content/get-content-versions';
import { GetPermissionsResponse } from '../models/get-permissions-response';
import { publishContentVersion } from '../fn/content/publish-content-version';
import { PublishContentVersion$Params } from '../fn/content/publish-content-version';
import { Transporter } from '../models/transporter';
import { updateContent } from '../fn/content/update-content';
import { UpdateContent$Params } from '../fn/content/update-content';
import { updateContentLocation } from '../fn/content/update-content-location';
import { UpdateContentLocation$Params } from '../fn/content/update-content-location';
import { updateContentVersion } from '../fn/content/update-content-version';
import { UpdateContentVersion$Params } from '../fn/content/update-content-version';
import { uploadAttachment } from '../fn/content/upload-attachment';
import { UploadAttachment$Params } from '../fn/content/upload-attachment';


/**
 * Služby pre stránky
 */
@Injectable({ providedIn: 'root' })
export class ContentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createContent()` */
  static readonly CreateContentPath = '/admin/content';

  /**
   * Vytvorenie stránky.
   *
   * Služba pre vytvorenie stránky
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createContent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContent$Response(params: CreateContent$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentDetail>> {
    return createContent(this.http, this.rootUrl, params, context);
  }

  /**
   * Vytvorenie stránky.
   *
   * Služba pre vytvorenie stránky
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createContent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContent(params: CreateContent$Params, context?: HttpContext): Observable<ContentDetail> {
    return this.createContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentDetail>): ContentDetail => r.body)
    );
  }

  /** Path part for operation `getContent()` */
  static readonly GetContentPath = '/admin/content/{id}';

  /**
   * Načítanie stránky.
   *
   * Služba pre načítanie stránky podľa id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent$Response(params: GetContent$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentDetail>> {
    return getContent(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie stránky.
   *
   * Služba pre načítanie stránky podľa id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent(params: GetContent$Params, context?: HttpContext): Observable<ContentDetail> {
    return this.getContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentDetail>): ContentDetail => r.body)
    );
  }

  /** Path part for operation `updateContent()` */
  static readonly UpdateContentPath = '/admin/content/{id}';

  /**
   * Aktualizácia stránky.
   *
   * Služba aktualizuje základné údaje stránky
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateContent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContent$Response(params: UpdateContent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return updateContent(this.http, this.rootUrl, params, context);
  }

  /**
   * Aktualizácia stránky.
   *
   * Služba aktualizuje základné údaje stránky
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateContent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContent(params: UpdateContent$Params, context?: HttpContext): Observable<boolean> {
    return this.updateContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `deleteContent()` */
  static readonly DeleteContentPath = '/admin/content/{id}';

  /**
   * Odstránenie stránky.
   *
   * Služba odstráni (soft delete) stránku podľa id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContent$Response(params: DeleteContent$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return deleteContent(this.http, this.rootUrl, params, context);
  }

  /**
   * Odstránenie stránky.
   *
   * Služba odstráni (soft delete) stránku podľa id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContent(params: DeleteContent$Params, context?: HttpContext): Observable<boolean> {
    return this.deleteContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getContentVersions()` */
  static readonly GetContentVersionsPath = '/admin/content/{id}/version';

  /**
   * Služba pre načítanie zoznamu verzií stránky.
   *
   * Služba načíta zoznam verzií podĺa id stránky
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContentVersions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentVersions$Response(params: GetContentVersions$Params, context?: HttpContext): Observable<StrictHttpResponse<GetContentListResponse>> {
    return getContentVersions(this.http, this.rootUrl, params, context);
  }

  /**
   * Služba pre načítanie zoznamu verzií stránky.
   *
   * Služba načíta zoznam verzií podĺa id stránky
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContentVersions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentVersions(params: GetContentVersions$Params, context?: HttpContext): Observable<GetContentListResponse> {
    return this.getContentVersions$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetContentListResponse>): GetContentListResponse => r.body)
    );
  }

  /** Path part for operation `createContentVersion()` */
  static readonly CreateContentVersionPath = '/admin/content/{id}/version';

  /**
   * Služba pre vytvorenie novej verzie.
   *
   * Služba vytvorý novú verziu pre stránku podľa id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createContentVersion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContentVersion$Response(params: CreateContentVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentDetail>> {
    return createContentVersion(this.http, this.rootUrl, params, context);
  }

  /**
   * Služba pre vytvorenie novej verzie.
   *
   * Služba vytvorý novú verziu pre stránku podľa id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createContentVersion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContentVersion(params: CreateContentVersion$Params, context?: HttpContext): Observable<ContentDetail> {
    return this.createContentVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentDetail>): ContentDetail => r.body)
    );
  }

  /** Path part for operation `getContentVersionById()` */
  static readonly GetContentVersionByIdPath = '/admin/content/{id}/version/{versionId}';

  /**
   * Načítanie detailu verzie.
   *
   * Služba načíta detail verzie podľa id stránky a id verzie
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContentVersionById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentVersionById$Response(params: GetContentVersionById$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentDetail>> {
    return getContentVersionById(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie detailu verzie.
   *
   * Služba načíta detail verzie podľa id stránky a id verzie
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContentVersionById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentVersionById(params: GetContentVersionById$Params, context?: HttpContext): Observable<ContentDetail> {
    return this.getContentVersionById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentDetail>): ContentDetail => r.body)
    );
  }

  /** Path part for operation `updateContentVersion()` */
  static readonly UpdateContentVersionPath = '/admin/content/{id}/version/{versionId}';

  /**
   * Aktualizácia verzie stránky.
   *
   * Služba aktualizuje verziu stránky podľa id stránky a id verzie
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateContentVersion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContentVersion$Response(params: UpdateContentVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentDetail>> {
    return updateContentVersion(this.http, this.rootUrl, params, context);
  }

  /**
   * Aktualizácia verzie stránky.
   *
   * Služba aktualizuje verziu stránky podľa id stránky a id verzie
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateContentVersion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContentVersion(params: UpdateContentVersion$Params, context?: HttpContext): Observable<ContentDetail> {
    return this.updateContentVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentDetail>): ContentDetail => r.body)
    );
  }

  /** Path part for operation `deleteContentVersion()` */
  static readonly DeleteContentVersionPath = '/admin/content/{id}/version/{versionId}';

  /**
   * Zmazanie verzie stránky.
   *
   * Služba odstráni verziu stránky podľa id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteContentVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContentVersion$Response(params: DeleteContentVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return deleteContentVersion(this.http, this.rootUrl, params, context);
  }

  /**
   * Zmazanie verzie stránky.
   *
   * Služba odstráni verziu stránky podľa id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteContentVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContentVersion(params: DeleteContentVersion$Params, context?: HttpContext): Observable<boolean> {
    return this.deleteContentVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getContentPermissions()` */
  static readonly GetContentPermissionsPath = '/admin/content/{id}/permissions';

  /**
   * Načítanie oprávnení pre stránku.
   *
   * Služba načíta zoznam oprávnení (produkty, spoločnosti) pre stránku podľa id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContentPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentPermissions$Response(params: GetContentPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<GetPermissionsResponse>> {
    return getContentPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie oprávnení pre stránku.
   *
   * Služba načíta zoznam oprávnení (produkty, spoločnosti) pre stránku podľa id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContentPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentPermissions(params: GetContentPermissions$Params, context?: HttpContext): Observable<GetPermissionsResponse> {
    return this.getContentPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetPermissionsResponse>): GetPermissionsResponse => r.body)
    );
  }

  /** Path part for operation `createContentPermission()` */
  static readonly CreateContentPermissionPath = '/admin/content/{id}/permissions';

  /**
   * Pridanie oprávnenia pre stránku.
   *
   * Služba pridá oprávnenie pre stránku podľa id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createContentPermission()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContentPermission$Response(params: CreateContentPermission$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return createContentPermission(this.http, this.rootUrl, params, context);
  }

  /**
   * Pridanie oprávnenia pre stránku.
   *
   * Služba pridá oprávnenie pre stránku podľa id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createContentPermission$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContentPermission(params: CreateContentPermission$Params, context?: HttpContext): Observable<boolean> {
    return this.createContentPermission$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `bulkDeleteContentPermissions()` */
  static readonly BulkDeleteContentPermissionsPath = '/admin/content/{id}/permissions';

  /**
   * Hromadné zmazanie oprávnení.
   *
   * Služba zmaže všetky oprávnenia pre content podľa typu
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkDeleteContentPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkDeleteContentPermissions$Response(params: BulkDeleteContentPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return bulkDeleteContentPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * Hromadné zmazanie oprávnení.
   *
   * Služba zmaže všetky oprávnenia pre content podľa typu
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkDeleteContentPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkDeleteContentPermissions(params: BulkDeleteContentPermissions$Params, context?: HttpContext): Observable<boolean> {
    return this.bulkDeleteContentPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `deleteContentPermissions()` */
  static readonly DeleteContentPermissionsPath = '/admin/content/{id}/permissions/{permissionId}';

  /**
   * Zmazanie oprávnenia.
   *
   * Služba slúži na zmazanie oprávnenia (produkty, spoločnosti) pre stránku podľa id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteContentPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContentPermissions$Response(params: DeleteContentPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return deleteContentPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * Zmazanie oprávnenia.
   *
   * Služba slúži na zmazanie oprávnenia (produkty, spoločnosti) pre stránku podľa id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteContentPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContentPermissions(params: DeleteContentPermissions$Params, context?: HttpContext): Observable<boolean> {
    return this.deleteContentPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getContentLocations()` */
  static readonly GetContentLocationsPath = '/admin/content/{id}/location';

  /**
   * Načítanie umiestnenia stránky.
   *
   * Služba načíta zoznam umiestnení (sekcie) pre stránku podľa id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContentLocations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentLocations$Response(params: GetContentLocations$Params, context?: HttpContext): Observable<StrictHttpResponse<GetContentLocationListResponse>> {
    return getContentLocations(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie umiestnenia stránky.
   *
   * Služba načíta zoznam umiestnení (sekcie) pre stránku podľa id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContentLocations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentLocations(params: GetContentLocations$Params, context?: HttpContext): Observable<GetContentLocationListResponse> {
    return this.getContentLocations$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetContentLocationListResponse>): GetContentLocationListResponse => r.body)
    );
  }

  /** Path part for operation `createContentLocation()` */
  static readonly CreateContentLocationPath = '/admin/content/{id}/location';

  /**
   * Pridanie umiestnenia stránky.
   *
   * Služba pridá umiestnenie (sekcia) pre stránku
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createContentLocation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContentLocation$Response(params: CreateContentLocation$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return createContentLocation(this.http, this.rootUrl, params, context);
  }

  /**
   * Pridanie umiestnenia stránky.
   *
   * Služba pridá umiestnenie (sekcia) pre stránku
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createContentLocation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContentLocation(params: CreateContentLocation$Params, context?: HttpContext): Observable<boolean> {
    return this.createContentLocation$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `updateContentLocation()` */
  static readonly UpdateContentLocationPath = '/admin/content/{id}/location/{sectionId}';

  /**
   * Aktualizácia umiestnenia.
   *
   * Služba slúži na aktualizáciu poradia stránky v sekcii, pričom automaticky posunie poradie ostatných stránok podľa potreby
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateContentLocation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContentLocation$Response(params: UpdateContentLocation$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return updateContentLocation(this.http, this.rootUrl, params, context);
  }

  /**
   * Aktualizácia umiestnenia.
   *
   * Služba slúži na aktualizáciu poradia stránky v sekcii, pričom automaticky posunie poradie ostatných stránok podľa potreby
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateContentLocation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContentLocation(params: UpdateContentLocation$Params, context?: HttpContext): Observable<boolean> {
    return this.updateContentLocation$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `deleteContentLocation()` */
  static readonly DeleteContentLocationPath = '/admin/content/{id}/location/{sectionId}';

  /**
   * Zmazanie umiestnenia.
   *
   * Služba slúži na zmazanie umiestnenia stránky podľa id a id umiestnenia.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteContentLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContentLocation$Response(params: DeleteContentLocation$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return deleteContentLocation(this.http, this.rootUrl, params, context);
  }

  /**
   * Zmazanie umiestnenia.
   *
   * Služba slúži na zmazanie umiestnenia stránky podľa id a id umiestnenia.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteContentLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContentLocation(params: DeleteContentLocation$Params, context?: HttpContext): Observable<boolean> {
    return this.deleteContentLocation$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `uploadAttachment()` */
  static readonly UploadAttachmentPath = '/admin/content/{id}/version/{versionId}/attachment';

  /**
   * Nahrávanie prilohy.
   *
   * Služba slúži na nahrávanie prílohy na stránku
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadAttachment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadAttachment$Response(params: UploadAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentAttachmentDetail>> {
    return uploadAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * Nahrávanie prilohy.
   *
   * Služba slúži na nahrávanie prílohy na stránku
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadAttachment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadAttachment(params: UploadAttachment$Params, context?: HttpContext): Observable<ContentAttachmentDetail> {
    return this.uploadAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentAttachmentDetail>): ContentAttachmentDetail => r.body)
    );
  }

  /** Path part for operation `getContentAttachment()` */
  static readonly GetContentAttachmentPath = '/admin/content/{id}/version/{versionId}/attachment/{attachmentId}';

  /**
   * Služba pre načítanie prílohy.
   *
   * Služba pre načítanie prílohy pre danú stránku podľa id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContentAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentAttachment$Response(params: GetContentAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<Transporter>> {
    return getContentAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * Služba pre načítanie prílohy.
   *
   * Služba pre načítanie prílohy pre danú stránku podľa id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContentAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentAttachment(params: GetContentAttachment$Params, context?: HttpContext): Observable<Transporter> {
    return this.getContentAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Transporter>): Transporter => r.body)
    );
  }

  /** Path part for operation `deleteAttachment()` */
  static readonly DeleteAttachmentPath = '/admin/content/{id}/version/{versionId}/attachment/{attachmentId}';

  /**
   * Zmazanie prílohy.
   *
   * Služba slúži na nahrávanie prílohy na stránku
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAttachment$Response(params: DeleteAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return deleteAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * Zmazanie prílohy.
   *
   * Služba slúži na nahrávanie prílohy na stránku
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAttachment(params: DeleteAttachment$Params, context?: HttpContext): Observable<boolean> {
    return this.deleteAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `publishContentVersion()` */
  static readonly PublishContentVersionPath = '/admin/content/{id}/version/{versionId}/publish';

  /**
   * Publikovanie verzie stránky.
   *
   * Služba publikuje zvolenú verziu stránky a nastaví dátum konca platnosti pre predchádzajúcu verzie podľa dátumu začiatku novej verzie
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publishContentVersion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  publishContentVersion$Response(params: PublishContentVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return publishContentVersion(this.http, this.rootUrl, params, context);
  }

  /**
   * Publikovanie verzie stránky.
   *
   * Služba publikuje zvolenú verziu stránky a nastaví dátum konca platnosti pre predchádzajúcu verzie podľa dátumu začiatku novej verzie
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publishContentVersion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  publishContentVersion(params: PublishContentVersion$Params, context?: HttpContext): Observable<boolean> {
    return this.publishContentVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
